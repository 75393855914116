<template>
  <b-table
    :data="list"
    paginated
    backend-pagination
    @page-change="onPageChange"
    :total="total"
    :per-page="limit"
    pagination-position="bottom"
  >
    <b-table-column field="name" label="이름" v-slot="props">
      {{ props.row.name }}
    </b-table-column>
    <b-table-column field="created_at" label="상담 신청 일자" v-slot="props">
      {{ props.row.created_at }}
    </b-table-column>
    <b-table-column field="detail" label="상세보기" v-slot="props">
      <b-button @click="onClickRow(props.row.token)">상세보기</b-button>
    </b-table-column>
  </b-table>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import client from '../api/client';
import { useRouter } from '@/use-router';

export default defineComponent({
  name: 'CounselingList',
  setup() {
    const list = ref([]);
    const router = useRouter();
    const offset = ref(0);
    const limit = ref(10);
    const total = ref(0);

    function onClickRow(token) {
      router.push({
        name: 'CounselingDetail',
        params: {
          token,
        },
      });
    }

    async function onPageChange(page) {
      const resp = await client.getCounselingList((page - 1) * limit.value, limit.value);
      list.value = resp.data.list;
    }

    onMounted(async () => {
      const resp = await client.getCounselingList(offset.value, limit.value);
      list.value = resp.data.list;
      total.value = resp.data.total;
    });

    return {
      limit,
      list,
      total,

      onClickRow,
      onPageChange,
    };
  },
});
</script>
